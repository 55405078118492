.campaign .header {
  font-size: 1.8em;
  color: #4267b2;
  font-weight: 600;
}

.campaign .actions {
  width: 17%;
}

.css-e110bw {
  z-index: 999;
  position: absolute;
}

.campaign .btn-floating,
.campaign .waves-effect,
.ReactModal__Body--open .fixed-action-btn {
  z-index: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 888;
}


@media (min-width: 768px) {
.container {
    max-width: 100%;
}
.scroll {
  max-height: 300px;
  overflow: auto;
}
}


@media screen and (max-height: 733px) {
  .scroll {
    max-height: 330px;
    overflow: auto;
  }
}

@media screen and (max-height: 733px) {
  .scrollMin {
    max-height: 330px;
    overflow: auto;
  }
}

@media screen and (max-height: 900) {
  .scroll {
    max-height: 600px;
    overflow: auto;
  }
}

.selectContainer {
  margin-bottom: 10px;
}

nav.navbar ul a {
  color: #fff;
 
}

nav.navbar {
  background: #4267b2;
}

nav.navbar .brand-logo {
  font-size: 2em;
}

nav.navbar .brand-logo {
  color: #fff;
  text-decoration: none!important;
}

.navbar-nav .nav-item {
  width: 60px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@media only screen and (min-width: 993px) {
  .container {
      width: 90%;
  }
}

.fixed-top {
  z-index: 999;
}

.bd-callout-info h3 {
  font-family: 'Roboto Slab', serif;
}
.bd-callout-info {
  font-family: 'Roboto Slab', serif;
}

.table.striped td {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.table.striped th {
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
  /* 
  font-family: 'Roboto', sans-serif; 
   font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Roboto Slab', serif; */
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 99999;
  position: absolute;
  background: #0000009c!important;
}
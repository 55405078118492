.login {
  text-align: center;
}

.login .header {
  font-size: 1.8em;
  color: #4267b2;
  font-weight: 600;
}

.login .send {
  background: #4267b2; 
}
